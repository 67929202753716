import { observer } from 'mobx-react-lite'
import { Model } from './Model'
import logo from './assets/stakeeLogo.png'
import logoDark from './assets/stakeeLogo.png'

interface Props {
    model: Model
}

const Footer = observer(({ model }: Props) => {
    return (
        <div className='mt-auto bg-milky font-body text-[#7836B5] dark:bg-dark-900 dark:text-dark-50'>
            <div className='mx-auto w-full max-w-screen-lg justify-between md:px-6 md:py-6 flex flex-row-reverse sm:items-start pb-16 px-2 sm:px-0'>
                    <div className='mx-8 my-4 flex justify-between gap-4'>
                        {/* <h3 className='font-bold text-[#7836B5] dark:text-[#7836B5'>Social</h3> */}
                        <a className='my-4 block text-sm' target='_blank' href='https://t.me/TronSterkNetwork'>
                            Telegram
                        </a>
                        <a className='my-4 block text-sm'  href='https://x.com/TON_ster' target='_blank' >
                            Twitter
                        </a>
                        <a className='my-4 block text-sm'  href='https://medium.com/@TronSterkNetwork' target='_blank'>
                            Blog
                        </a>
                    </div>

                    {/* <div className='mx-8 my-4'>
                        <h3 className='font-bold text-[#7836B5] dark:text-[#7836B5'>Community</h3>
                        <a className='my-4 block text-sm'  href='#' >
                             Chat
                        </a>
                        <a className='my-4 block text-sm'  href='#' >
                             Hub
                        </a>
                    </div>

                    <div className='mx-8 my-4'>
                        <h3 className='font-bold text-[#7836B5] dark:text-[#7836B5]'>Docs</h3>
                        <a className='my-4 block text-sm'  href='#' >
                            Audits
                        </a>
                        <a className='my-4 block text-sm'  href='#' >
                            GitHub
                        </a>
                        <a className='my-4 block text-sm'  href='#' >
                            Documentation
                        </a>
                    </div>

                    <div className='mx-8 my-4'>
                        <h3 className='font-bold text-[#7836B5] dark:text-[#7836B5]'>About</h3>
                        <a className='my-4 block text-sm'  href='#' >
                            Roadmap
                        </a>
                        <a className='my-4 block text-sm'  href='#'>
                            How  Works?
                        </a>
                        <a className='my-4 block text-sm'  href='#'>
                            Why Choose ?
                        </a>
                    </div> */}

                <div
                    className='flex select-none flex-row items-center gap-4 font-logo text-2xl dark:text-[#7836B5]'
                    onClick={model.switchNetwork}
                >
                    <img src={logo} className='h-20 dark:hidden' />
                    <img src={logoDark} className='hidden h-20 dark:block' />
                    <p></p>
                </div>
            </div>
        </div>
    )
})

export default Footer
