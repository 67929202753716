import { observer } from 'mobx-react-lite'
import { Model } from './Model.ts'
import Header from './Header.tsx'
import OldWalletUpgrade from './OldWalletUpgrade.tsx'
import StakeUnstake from './StakeUnstake.tsx'
import Defi from './Defi.tsx'
import Referral from './Referral.tsx'
import Wait from './Wait.tsx'
import Stats from './Stats.tsx'
import Footer from './Footer.tsx'
import ErrorDisplay from './ErrorDisplay.tsx'
import { useEffect } from 'react'
import Task from './Task.tsx'

interface Props {
    model: Model
}

export function _window(): any {
    return window
}

const App = observer(({ model }: Props) => {
    useEffect(() => {
        const window: any = _window()
        if (window.Telegram && window.Telegram.WebApp) {
            const app = window.Telegram.WebApp
            app.ready()
            app.headerColor = '#221f1f'
            app.expand()
            // app.disableVerticalSwipes()
        } else {
            console.error('Telegram WebApp is not available.')
        }
    }, [])
    let page = (
        <>
            <OldWalletUpgrade model={model} />
            <StakeUnstake model={model} />
            <Wait model={model} />
            <Stats model={model} />
        </>
    )
    if (model.activePage === 'defi') {
        page = <Defi model={model} />
    } else if (model.activePage === 'referral') {
        page = <Referral model={model} />
    }else if (model.activePage === 'task') {
        page = <Task model={model} />
    }

    return (
        <>
            <Header model={model} />
            {page}
            <Footer model={model} />
            {/* <LoadingIndicator model={model} /> */}
            <ErrorDisplay model={model} />
        </>
    )
})

export default App
