import { observer } from 'mobx-react-lite'
import { Model } from './Model'
import invite from './assets/invite.png'
import inviteDark from './assets/invite-dark.png'
import reward from './assets/reward.png'
import rewardDark from './assets/reward-dark.png'

interface Props {
    model: Model
}

const Referral = observer(({ model }: Props) => {
    
    model.loadReferralStats()

     
    return (
        <div className='mx-auto w-full max-w-screen-lg p-4 pb-32 font-body text-[#7836B5] dark:text-dark-50'>
            <p className='px-8 pt-4 text-center text-3xl font-bold'> Referral Program</p>
            <p className='my-8 px-8 text-center text-xl'>Earn Rewards by Sharing </p>

            <div className='my-8 flex-row items-center sm:flex'>
                <img src={invite} className='w-full dark:hidden sm:w-0 sm:flex-1' />
                <img src={inviteDark} className='hidden w-full dark:block sm:w-0 sm:flex-1' />
                <div className='p-8 sm:flex-1'>
                    <h3 className='py-4 text-2xl font-bold'>1. Invite Others</h3>
                    <p className='text-lg'>
                        Share your unique referral link with friends, family, and community members.
                    </p>
                </div>
            </div>

            <div className='my-8 flex-row-reverse items-center sm:flex'>
                <img src={reward} className='w-full dark:hidden sm:w-0 sm:flex-1' />
                <img src={rewardDark} className='hidden w-full dark:block sm:w-0 sm:flex-1' />
                <div className='p-8 sm:flex-1'>
                    <h3 className='py-4 text-2xl font-bold'>2. Earn Rewards</h3>
                    <p className='text-lg'>Receive 2.5% of the stake amount for every successful referral.</p>
                </div>
            </div>

        </div>
    )
})

export default Referral
