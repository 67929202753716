import { getHttpEndpoint } from "@orbs-network/ton-access";
import { TonClient } from "@ton/ton";

import { useAsyncInitialize } from "./useAsyncInitialize";

export const network = (import.meta.env.VITE_TON_NETWORK as "mainnet" | "testnet") || "testnet";
console.log(network);

export function useTonClient(): TonClient | undefined {
  return useAsyncInitialize(
    async () =>
      new TonClient({
        // endpoint: "https://testnet.toncenter.com/api/v2/jsonRPC",
        endpoint: await getHttpEndpoint({ network }),
      }),
  );
}
