import { observer } from 'mobx-react-lite'
import { Model } from './Model'
import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'
import { useEffect, useState } from 'react'
import { get, post } from './service/apiService'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ClipLoader from 'react-spinners/ClipLoader'

interface Props {
    model: Model
}

const Task = observer(({ model }: Props) => {
    const [tonConnectUI] = useTonConnectUI()
    const wallet = useTonWallet()
    const [isWalletConnected, setIsWalletConnected] = useState<Boolean>(false)
    const [taskList, setTaskList] = useState([])
    const [earnToken, setEarnToken] = useState(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [timerLoader, setTimerLoader] = useState<boolean[]>([])
    const [apiCallLoader, setApiCallLoader] = useState<boolean[]>([])
    const [showCheck, setShowCheck] = useState<boolean[]>([])

    const checkUserExist = async (walletAddress) => {
        try {
            const result = await post('check-user-exist', { walletAddress })
            localStorage.setItem('access_token', result.data.token)
            setTimeout(() => {
                getTaskData()
            }, 800)
        } catch (error) {
            localStorage.removeItem('access_token')
            console.log(error)
            model.setErrorMessage(error?.response?.data?.message, 1000)
        }
    }

    const getTaskData = async () => {
        try {
            const result = await get('community-list')
            setTaskList(result.data.data)
            setEarnToken(result.data.points)
            setTimerLoader(new Array(result.data.data.length).fill(false))
            setApiCallLoader(new Array(result.data.data.length).fill(false))
            setShowCheck(new Array(result.data.data.length).fill(false))
        } catch (error) {
            console.log(error)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    const handleClaim = async (communityId, index) => {
        try {
            setApiCallLoader((prev) => {
                const newLoader = [...prev]
                newLoader[index] = true
                return newLoader
            })
            const result = await get(`claim-community?communityId=${communityId}`)
            model.setErrorMessage(result.data.message, 1000)
            getTaskData()

        } catch (error) {
            model.setErrorMessage(error?.response?.data?.message, 1000)
            console.log(error)
        } finally {
            setApiCallLoader((prev) => {
                const newLoader = [...prev]
                newLoader[index] = false
                return newLoader
            })
        }
    }

    const handleTimer = async (index) => {
        setTimerLoader((prev) => {
            const newLoader = [...prev]
            newLoader[index] = true
            return newLoader
        })
        setTimeout(() => {
            setShowCheck((prev) => {
                const newCheck = [...prev]
                newCheck[index] = true
                return newCheck
            })
        }, 15000)
    }

    useEffect(() => {
        setIsWalletConnected(!!wallet)
        if (wallet) {
            checkUserExist(wallet.account.address)
        }
    }, [wallet])

    const isAnyLoaderActive = timerLoader.includes(true) || apiCallLoader.includes(true)
   
    return (
        <div className='font-body mx-auto w-full max-w-screen-lg pb-32 pt-8 text-[#7836B5] dark:text-dark-50'>
            {!isWalletConnected && (
                <>
                    <div className='pb-8 sm:flex-1'>
                        <h3 className='pb-4 text-center text-2xl font-bold'>Task To Earn Bonus</h3>
                        <p className='text-center text-lg'>
                            We’ll reward you immediately with points after each task completion
                        </p>
                    </div>
                    <button
                        className='mx-auto block h-14 rounded-2xl bg-[#7836B5] px-4 text-lg font-medium text-white dark:text-white'
                        onClick={(e) => {
                            tonConnectUI.openModal()
                        }}
                    >
                        {'Connect Wallet'}
                    </button>
                </>
            )}
            {isWalletConnected && (
                <>  
                    <div className='mx-auto flex w-full flex-col gap-3 rounded-lg bg-[#292626] p-3 md:p-6 lg:w-1/2'>
                        <div className='flex items-center justify-between'>
                          <h3 className='text-center text-2xl font-bold text-white'>Task List</h3>
                          <p className='text-xs flex flex-col gap-1 items-center'><b className='text-lg'>{earnToken}</b> <span className='text-gray-400'>(Points Earn)</span></p>
                        </div>
                        {loading
                            ? [1, 2, 3, 4, 5].map((_, index) => (
                                  <Skeleton key={index} baseColor='#2d2a2a' highlightColor='#4b4848' className='h-[61.2px]' />
                              ))
                            : taskList.map((item, index) => (
                                  <div
                                      key={item.communityId}
                                      className='flex items-center justify-between rounded-md bg-dark-900 p-2 text-white'
                                  >
                                      <div className='flex items-center gap-3'>
                                          <img src={item.logo} alt={item.name} height={45} width={45} />
                                          <div>
                                              <p className='text-sm font-medium'>{item.name}</p>
                                              <p className='flex text-xs font-normal text-gray-400'>
                                                  {/* <img src='/images/svg_img/coin.svg' alt='' /> */}
                                                   +{item.points} coin
                                              </p>
                                          </div>
                                      </div>
                                      {item.status ? (
                                          <p>Claimed</p>
                                      ) : (
                                          <>
                                              {!showCheck[index] && (
                                                  <a
                                                      onClick={() => handleTimer(index)}
                                                      href={item.link}
                                                      target='_blank'
                                                      className={`rounded-md border border-white px-3 py-1 text-sm hover:border-[#7836b5] hover:bg-[#7836b5] ${
                                                          isAnyLoaderActive || showCheck[index] ? 'pointer-events-none opacity-50' : ''
                                                      }`}
                                                  >
                                                      {timerLoader[index] ? (
                                                          <ClipLoader
                                                              size={20}
                                                              color={'#ffffff'}
                                                              loading={timerLoader[index]}
                                                          />
                                                      ) : (
                                                          'Claim'
                                                      )}
                                                  </a>
                                              )}
                                              {showCheck[index] && (
                                                  <button
                                                      onClick={() => handleClaim(item.communityId, index)}
                                                      className={`rounded-md border border-white px-3 py-1 text-sm hover:border-[#7836b5] hover:bg-[#7836b5] ${
                                                          isAnyLoaderActive && !showCheck[index] ? 'pointer-events-none opacity-50' : ''
                                                      }`}
                                                  >
                                                         {apiCallLoader[index] ? (
                                                          <ClipLoader
                                                              size={20}
                                                              color={'#ffffff'}
                                                              loading={apiCallLoader[index]}
                                                          />
                                                      ) : (
                                                          'Check'
                                                      )}
                                                  </button>
                                              )}
                                          </>
                                      )}
                                  </div>
                              ))}
                    </div>
                </>
            )}
        </div>
    )
})

export default Task
