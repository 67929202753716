import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { Model } from './Model.ts'
import '@twa-dev/sdk'
import './index.css'
import { TonConnectUIProvider } from '@tonconnect/ui-react'

const model = new Model()
model.init()
const manifestUrl = "https://hippo.codetentaclestechnologies.tech/tonconnect-manifest.json"
ReactDOM.createRoot(document.querySelector('#root') ?? document.body).render(
    <React.StrictMode>
        <TonConnectUIProvider
            manifestUrl={manifestUrl}
            actionsConfiguration={{
                twaReturnUrl: 'https://t.me/testTonster_bot',
            }}
        >
            <App model={model} />
        </TonConnectUIProvider>
    </React.StrictMode>,
)
